import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const IndexPage = () => {
  const [isDark, setIsDark] = React.useState(false);

  React.useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setIsDark(true);
    }
  }, []);

  React.useEffect(() => {
    if (isDark) {
      document.body.style = "background: black;";
    } else {
      document.body.style = "background: white;";
    }
  }, [isDark]);

  return (
    <Layout>
      <Seo title="Home" />
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
        }}
      >
        {isDark ? (
          <StaticImage
            style={{ maxWidth: 800 }}
            src={`../images/dark.png`}
            alt="rollersoft-logo"
            placeholder="none"
          />
        ) : (
          <StaticImage
            style={{ maxWidth: 800 }}
            src={`../images/light.png`}
            alt="rollersoft-logo"
            placeholder="none"
          />
        )}
      </div>
    </Layout>
  );
};

export default IndexPage;
